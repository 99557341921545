import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Block = memo(function Block({ description, image, title }: Props) {
  return (
    <Container>
      {image ? <Image {...image} /> : null}
      <Wrapper className="block-text">
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  &:first-of-type {
    margin-top: 3.75rem;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
    backface-visibility: hidden;
  }
  &:hover {
    &:before {
      opacity: 0.6;
    }
    img {
      transform: scale(1.1);
    }
    .block-text {
      bottom: 0;
      > div {
        opacity: 1;
        transform: none;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 75%;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: -4.125rem;
  left: 0;
  width: 100%;
  padding: 0 3.75rem 3.75rem;
  transition: 0.3s ease-in-out;
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 2.25rem;
    padding: 0 2.25rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    letter-spacing: 0.045rem;
    line-height: 1.8125rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 0.8125rem;
  opacity: 0;
  transform: translateY(0.8125rem);
  transition: 0.3s ease-in-out;

  @media (max-width: 1199px) {
    display: none;
  }
`
