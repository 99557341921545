import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  backgroundImage?: ImageProps
  created?: string
  languageCode: string
  title?: string
  URL: string
}

export const Post = memo(function Post({
  backgroundImage,
  created,
  languageCode,
  title,
  URL,
}: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {backgroundImage ? <Image {...backgroundImage} /> : null}
      <Wrapper>
        {created ? (
          <Created>
            {new Date(created).toLocaleDateString(languageCode, {
              year: 'numeric',
              month: 'long',
            })}
          </Created>
        ) : null}
        {title ? <Title>{title}</Title> : null}
        <CTA
          className="post-cta"
          label={useVocabularyData('read', languageCode)}
          noActions
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  &:hover {
    &:before {
      opacity: 0.5;
    }
    img {
      transform: scale(1.1);
    }
    .post-cta {
      &:after {
        top: 0;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }
`

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  overflow: hidden;
  position: absolute;
  bottom: 3rem;
  right: 7.5rem;
  left: 3rem;
  z-index: 2;
`

const Created = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 0.9375rem;
  margin-bottom: 0.5625rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5rem;
`

const CTA = styled(Button)`
  margin-top: 1.875rem;
`
