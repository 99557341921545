import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  backgroundImage?: string
  description?: string
}

export const VisualParagraph = memo(function VisualParagraph({
  backgroundImage,
  description,
}: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      <Fade>
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 10.9375rem 10vw;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 200;
  line-height: 5.25rem;
  background-size: cover;
  background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;

  // safari browser rules
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      -webkit-text-fill-color: ${({ theme }) =>
        theme.colors.variants.primaryDark1};
    }
  }
`
