import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Slider from 'react-slick'

import { Props as ReviewProps, Review } from './Review'

export interface Props {
  facebookURL?: string
  hashtags?: string[]
  image?: ImageProps
  instagramURL?: string
  languagePrefix: string
  pageURL?: string
  reviews?: ReviewProps[]
  title?: string
}

function PrevArrow(props: any) {
  return <ArrowLeft className="slider-prev" onClick={props.onClick} />
}

function NextArrow(props: any) {
  return <ArrowRight className="slider-next" onClick={props.onClick} />
}

export const SocialReviews = memo(function SocialReviews({
  facebookURL,
  hashtags,
  image,
  instagramURL,
  pageURL,
  reviews,
  title,
}: Props) {
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <Container row stretch tag="section" wrap>
      {pageURL ? (
        <Social>
          {image ? <Image {...image} /> : null}
          <Wrapper>
            {title ? <Title>{title}</Title> : null}
            {hashtags
              ? hashtags.map((item, index) => (
                  // @ts-ignore
                  <Hashtag key={index}>{item.hashtag}</Hashtag>
                ))
              : null}
            <WrapperIcon row dial={2} space="between">
              <Icon href={facebookURL} rel="noreferrer" target="_blank">
                <SVG
                  src="./icon_facebook.svg"
                  width="32"
                  height="32"
                  alt="Facebook"
                />
              </Icon>
              <Icon href={instagramURL} rel="noreferrer" target="_blank">
                <SVG
                  src="./icon_instagram.svg"
                  width="32"
                  height="32"
                  alt="Instagram"
                />
              </Icon>
            </WrapperIcon>
          </Wrapper>
        </Social>
      ) : null}
      {reviews && reviews.length ? (
        <Reviews>
          <Slider {...settings}>
            {reviews.map((item, index) => (
              <Review key={index} {...item} />
            ))}
          </Slider>
        </Reviews>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 12.5rem 10vw 0;

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const Social = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 4.375rem 3.75rem 3.75rem;
  position: relative;
  text-align: center;
  /* &:hover {
    .icon-arrow {
      background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
      border-color: transparent;
    }
    img {
      transform: scale(1.1);
    }
  } */
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backface-visibility: hidden;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3.75rem 8.454vw;
  }
`

const WrapperIcon = styled(FlexBox)`
  margin: 2.5rem auto 0;
  width: 4rem;
`

const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`

const SVG = styled.img`
  width: auto;
  max-height: 1.5rem;
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 1.25rem;
`

const Hashtag = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: 0.375rem;
`

/* const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  margin: 2.875rem auto 0;
  transition: 0.3s ease-in-out;
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }
` */

const Reviews = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 2.5rem;
  transform: translateY(3.75rem);
  &:before {
    content: '';
    display: block;
    width: 2.125rem;
    height: 1.5rem;
    background: url('/quotation.svg') no-repeat top center;
    opacity: 0.16;
    margin: 0 auto 1rem;
  }
  > div {
    padding-bottom: 80px;
    position: relative;
  }
  .slider-prev,
  .slider-next {
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1.2;
    transition: 0.3s ease-in-out;
    &:hover {
      stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  .slider-prev {
    left: calc(50% - 2.25rem);
  }
  .slider-next {
    right: calc(50% - 2.25rem);
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3.75rem 8.454vw;
    transform: none;
  }
`
